.WidgetListItem {
  padding: 10px;
  border-bottom: 1px #a2a2a2 solid;
}

.WidgetListItem:hover {
  color: white;
  background-color: black;
  background-color: var(--WidgetList-select-color);
  cursor: pointer;
}

.WidgetListItem-info {
  font-size: small;
  color: silver;
}