.WidgetSettings h2:first-child {
  margin-top: 0;
}

.WidgetSettings-content {
  display: flex;
  flex-direction: row;
}

.WidgetSettings-navigation {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-right: 25px;
}

.WidgetSettings-paneContainer {
  flex: 1;
}