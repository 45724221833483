.WidgetList {
  --WidgetList-select-color: black;
}

.NewWidgetListItem {
  padding: 5px;
  background: var(--WidgetList-select-color);
}
.NewWidgetListItem input {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  outline: none;
}