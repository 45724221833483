.WidgetsPage {

}

.WidgetsScreen-Content {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.WidgetsScreen-WidgetList {
  width: 30%;
  max-width: 300px;
  min-width: 100px;
  box-shadow: 1px 4px 3px 0px #00000099;
  overflow-y: scroll;
}

.WidgetsScreen-WidgetPane {
  padding: 20px;
  flex: 1;
  overflow-y: scroll;
}