.Screen {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Screen-Content {
  flex: 1;
}

.Header {
  padding: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;

  box-shadow: 1px 1px 7px 0px #00000099;
}

.Header-PageSelector {
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 20px;
}

.Header-Buttons {
  flex: 1;
}

.Header-RightButtons a {
  margin-left: 10px;
}